@keyframes styles_marker-pulse__BxsPp {
    0% {
        transform: translate(-50%,-50%) scale(1);
        opacity: 1
    }
  
    35% {
        transform: translate(-50%,-50%) scale(8);
        opacity: 0
    }
  
    to {
        transform: translate(-50%,-50%) scale(8);
        opacity: 0
    }
  }
  
  .styles_marker__Mzm27 {
    box-shadow: 0 16px 31px rgba(0,0,0,.13),0 10.3704px 18.1551px rgba(0,0,0,.099),0 6.16296px 9.87407px rgba(0,0,0,.079),0 3.2px 5.0375px rgba(0,0,0,.065),0 1.3037px 2.52593px rgba(0,0,0,.051),0 .296296px 1.21991px rgba(0,0,0,.031)
  }
  
  .styles_marker-pulse__BxsPp {
    animation: styles_marker-pulse__BxsPp 4s ease-out infinite
  }
  
  .styles_marker-border__fxi6v {
    background: linear-gradient(0deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.12))
  }
  .s-3 {
    width: 0.75rem;
    height: 0.75rem;
  }
  
  .opacity-20 {
    opacity: .2;
  }
  .bg-\[\#679BFF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(103 155 255/var(--tw-bg-opacity));
  }
  .rounded-full {
    border-radius: 9999px;
  }

  /* Pulso AVAILABLE  */

  .blobs-container {
    display: flex;
  }
  
  .blob {
    background: black;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    /* margin: 10px; */
    height: 10px;
    width: 10px;
    transform: scale(1);
    animation: pulse-black 2s infinite;
  }
  
  @keyframes pulse-black {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  
  .blob.white {
    background: white;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    animation: pulse-white 2s infinite;
  }
  
  @keyframes pulse-white {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  
  .blob.red {
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
  }
  
  @keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }
  
  .blob.orange {
    background: rgba(255, 121, 63, 1);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
    animation: pulse-orange 2s infinite;
  }
  
  @keyframes pulse-orange {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
  }
  
  .blob.yellow {
    background: rgba(255, 177, 66, 1);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
    animation: pulse-yellow 2s infinite;
  }
  
  @keyframes pulse-yellow {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
  }
  
  .blob.blue {
    background: rgba(52, 172, 224, 1);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
    animation: pulse-blue 2s infinite;
  }
  
  @keyframes pulse-blue {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
  }
  
  .blob.green {
    background: rgba(0, 187, 131, .9);
    box-shadow: 0 0 0 0 rgba(0, 187, 131, 1);
    animation: pulse-green 2s infinite;
  }
  
  @keyframes pulse-green {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 187, 131, .7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 5px rgba(51, 217, 178, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
  }
  
  .blob.purple {
    background: rgba(142, 68, 173, 1);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 1);
    animation: pulse-purple 2s infinite;
  }
  
  @keyframes pulse-purple {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
    }
  }
  